<template>
  <div>
    <b-card>
      <h5>Short Link</h5>
      <b-alert variant="primary" show>
        <div class="alert-body">
          <feather-icon icon="InfoIcon" class="mr-50" />
          <span>
            <strong>คำแนะนำ!</strong>
            ระบบนี้รองรับลิงค์ ที่เกี่ยวข้องกับการ share บน app หรือ web ของ ongphrawallpaper เท่านั้น
          </span>
        </div>
      </b-alert>
      <validation-observer ref="refFormShortLink">
        <b-form>
          <b-form-group>
            <validation-provider #default="{ errors }" name="urlShotLink" rules="required|url">
              <b-input-group>
                <b-form-input v-model="urlLink" :state="errors.length > 0 ? false : null" placeholder="ระบุลิงค์" />
                <!-- <b-input-group-append v-if="urlLink" is-text>
                  <feather-icon icon="XIcon" />
                </b-input-group-append> -->
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <div v-if="urlShortLink">
        <span>ลิงค์ใหม่ที่ได้</span>
        <div class="">
          <span class="mr-50">{{ urlShortLink }}</span>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="copyShortLink"
          >
            <feather-icon icon="CopyIcon" size="14" />
          </b-button>
        </div>
      </div>
      <button-widget
        text="แปลงลิงค์ & คัดลอก"
        variant="primary"
        block
        class="mt-1"
        :loading="loading"
        @click="createShortLink"
      />
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return { urlLink: '', loading: false, urlShortLink: '' }
  },
  methods: {
    async createShortLink() {
      const validate = await this.$refs.refFormShortLink?.validate()
      if (!validate) return
      this.urlShortLink = ''
      this.loading = true
      const formData = new FormData()
      formData.append('urlshorten', this.urlLink)

      const resp = await axios.post('https://asia1.ongphrawallpaper.com/shorten', formData).catch(() => null)
      this.loading = false

      let urlForShare = `${this.urlLink}`

      if (resp) {
        if (resp.status === 200) {
          const { data } = resp

          if (typeof data === 'string' && data.startsWith('https://ophra.me')) {
            urlForShare = data
          }
        }
      } else {
        const { hostname } = new URL(this.urlLink)

        const hostArr = hostname.split('.')

        if (hostArr[hostArr.length - 2] !== 'ongphrawallpaper') {
          this.gDisplayToast(
            'ลิงค์นี้ไม่รองรับ',
            'กรุณาใช้ลิงค์ ที่เกิดจากการ share บน app หรือ web ของ ongphrawallpaper เท่านั้น',
            'danger',
          )

          return
        }
      }

      this.urlShortLink = `${urlForShare}`

      const result = await this.$copyText(urlForShare).catch(() => {})
      if (!result.text) {
        this.gDisplayToast('คัดลอกไม่สำเร็จ', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
        return
      }
      this.gDisplayToast('คัดลอกแล้ว')
    },
    async copyShortLink() {
      const result = await this.$copyText(this.urlShortLink).catch(() => {})
      if (!result.text) {
        this.gDisplayToast('คัดลอกไม่สำเร็จ', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
        return
      }
      this.gDisplayToast('คัดลอกแล้ว')
    },
  },
}
</script>

<style lang="scss" scoped></style>
